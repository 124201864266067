html,
body,
#root
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #141c21;
}

.overlay
{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.logo
{
  position: absolute;
  top: 1%;
  left: 1%;
}
